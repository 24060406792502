import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

// -----------------  AUTH -------------------------------
const Loader = lazy(() => import('./components/loader.jsx'));
const Footer = lazy(() => import('./components/footer.jsx'));
const Login = lazy(() => import('./pages/auth/login.jsx'));
const Setting = lazy(() => import('./components/setting.jsx'));
const Protected = lazy(() => import('./components/protected.config.js'));
const PageNotFound = lazy(() => import('./components/pageNotFound.jsx'));
const ForgetPassword = lazy(() => import('./pages/auth/forgetPassword.jsx'));
const ResetPassword = lazy(() => import('./pages/auth/resetPassword.jsx'));
const ChangePassword = lazy(() => import('./pages/auth/changePassword.jsx'));
const Profile = lazy(() => import('./pages/auth/profile.jsx'));

const Dashboard = lazy(() => import('./pages/dashboard.jsx'));

// ------------------------ CUSTOMER --------------------------------
const Customer = lazy(() => import('./pages/customers/customer.jsx'));
const ViewCustomer = lazy(() => import('./pages/customers/view.jsx'));
const EditCustomer = lazy(() => import('./pages/customers/edit.jsx'));

// ------------------------- INDUSTRY ------------------------------
const Industry = lazy(() => import('./pages/industry/industry.jsx'));
const ViewIndustry = lazy(() => import('./pages/industry/view.jsx'));
const EditIndustry = lazy(() => import('./pages/industry/edit.jsx'));
const AddIndustry = lazy(() => import('./pages/industry/add.jsx'));

// ---------------------  BANNER  -------------------------------
const Banner = lazy(() => import('./pages/banner/banner.jsx'));
const EditBanner = lazy(() => import('./pages/banner/edit.jsx'));
const AddBanner = lazy(() => import('./pages/banner/add.jsx'));

// -------------------   REPORT   ----------------------------
const Report = lazy(() => import('./pages/report/report.jsx'));
const ViewReport = lazy(() => import('./pages/report/view.jsx'));
const EditReport = lazy(() => import('./pages/report/edit.jsx'));
const AddReport = lazy(() => import('./pages/report/add.jsx'));

//   ------------------  NEWS  -------------------------
const News = lazy(() => import('./pages/news/news.jsx'));
const ViewNews = lazy(() => import('./pages/news/view.jsx'));
const AddNews= lazy(() => import('./pages/news/add.jsx'));
const EditNews = lazy(() => import('./pages/news/edit.jsx'));

// ------------------------  SUB ADMIN  ----------------------------
const SubAdmin = lazy(() => import('./pages/subAdmin/subAdmin.jsx'));
const AddSubAdmin = lazy(() => import('./pages/subAdmin/add.jsx'));
const EditSubAdmin = lazy(() => import('./pages/subAdmin/edit.jsx'));

//  --------------------------  NOTIFICATION  ---------------------------------
const Notification = lazy(() => import('./pages/notification/notification.jsx'));
const AddNotification= lazy(() => import('./pages/notification/add.jsx'));

//  --------------------------  CUSTOM RESEARCH  ---------------------------------
const CustomResearch = lazy(() => import('./pages/customResearch/customResearch.jsx'));
const ViewCustomResearch = lazy(() => import('./pages/customResearch/view.jsx'));


// ---------------------  OFFICES -------------------------------
const Office = lazy(() => import('./pages/office/office.jsx'));
const EditOffice = lazy(() => import('./pages/office/edit.jsx'));
const AddOffice = lazy(() => import('./pages/office/add.jsx'));
const ViewOffice = lazy(() => import('./pages/office/view.jsx'));

// ---------------------  KEYWORD  -------------------------------
const Keyword = lazy(() => import('./pages/keywords/keywords.jsx'));
const EditKeyword = lazy(() => import('./pages/keywords/edit.jsx'));
const AddKeyword = lazy(() => import('./pages/keywords/add.jsx'));

//   ------------------  CMS  -------------------------
const Cms = lazy(() => import('./pages/cms/cms.jsx'));
const ViewCms = lazy(() => import('./pages/cms/view.jsx'));
const AddCms= lazy(() => import('./pages/cms/add.jsx'));
const EditCms = lazy(() => import('./pages/cms/edit.jsx'));

// ------------------------- INDUSTRY ------------------------------
const Brochure = lazy(() => import('./pages/brochure/brochure.jsx'));
const ViewBrochure = lazy(() => import('./pages/brochure/view.jsx'));
const EditBrochure = lazy(() => import('./pages/brochure/edit.jsx'));
const AddBrochure = lazy(() => import('./pages/brochure/add.jsx'));

// ---------------------  RATING-REVIEW  -------------------------------
const Rating = lazy(() => import('./pages/rating/rating.jsx'));
const ViewRating = lazy(() => import('./pages/rating/view.jsx'));

// ---------------------  ORDER  -------------------------------
const Order = lazy(() => import('./pages/order/order.jsx'));
const ViewOrder = lazy(() => import('./pages/order/view.jsx'));


const AuthenticatedRoutes = () => {
  return (
    <Suspense fallback={<Loader/>}>
      <Protected>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />}/>
          <Route path="/change-password" element={<ChangePassword />}/>
          <Route path="/profile" element={<Profile />}/>
          <Route path="/setting" element={<Setting />}/>
          <Route path="/customer" element={<Customer />}/>
          <Route path="/view-customer" element={<ViewCustomer/>}/>
          <Route path="/edit-customer" element={<EditCustomer/>}/>
          <Route path="/industry" element={<Industry />}/>
          <Route path="/view-industry" element={<ViewIndustry/>}/>
          <Route path="/edit-industry" element={<EditIndustry/>}/>
          <Route path="/add-industry" element={<AddIndustry/>}/>
          {/* <Route path="/banner" element={<Banner/>}/>
          <Route path="/edit-banner" element={<EditBanner/>}/>
          <Route path="/add-banner" element={<AddBanner/>}/> */}
          <Route path="/report" element={<Report/>}/>
          <Route path="/view-report" element={<ViewReport/>}/>
          <Route path="/edit-report" element={<EditReport/>}/>
          <Route path="/add-report" element={<AddReport/>} />
          <Route path="/news" element={<News />} />
          <Route path="/view-news" element={<ViewNews/>} />
          <Route path="/add-news" element={<AddNews/>} />
          <Route path="/edit-news" element={<EditNews/>} />
          <Route path="/sub-admin" element={<SubAdmin/>} />
          <Route path="/add-sub-admin" element={<AddSubAdmin/>} />
          <Route path="/edit-sub-admin" element={<EditSubAdmin/>} />
          <Route path="/notification" element={<Notification/>} />
          <Route path="/add-notification" element={<AddNotification/>} />
          <Route path="/custom-research" element={<CustomResearch/>} />
          <Route path="/view-custom-research" element={<ViewCustomResearch/>} />
          <Route path="/office" element={<Office/>}/>
          <Route path="/edit-office" element={<EditOffice/>}/>
          <Route path="/add-office" element={<AddOffice/>}/>
          <Route path="/view-office" element={<ViewOffice/>}/>
          {/* <Route path="/view-keyword" element={<ViewKeyword/>}/> */}
          <Route path="/edit-keyword" element={<EditKeyword/>}/>
          <Route path="/add-keyword" element={<AddKeyword/>}/>
          <Route path="/keyword" element={<Keyword/>}/>
          <Route path="/cms" element={<Cms />} />
          <Route path="/view-cms" element={<ViewCms/>} />
          <Route path="/add-cms" element={<AddCms/>} />
          <Route path="/edit-cms" element={<EditCms/>} />
          <Route path="/brochure" element={<Brochure />}/>
          <Route path="/view-brochure" element={<ViewBrochure/>}/>
          <Route path="/edit-brochure" element={<EditBrochure/>}/>
          <Route path="/add-brochure" element={<AddBrochure/>}/>
          <Route path="/rating-review" element={<Rating />}/>
          <Route path="/view-rating-review" element={<ViewRating/>}/>
          <Route path="/order" element={<Order/>}/>
          <Route path="/view-order" element={<ViewOrder/>}/>
          <Route path="*" element={<PageNotFound/>} />
        </Routes>
        <Footer/>
      </Protected>
    </Suspense>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader/>}>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path='/*' element={<AuthenticatedRoutes />} />
        </Routes>
      </Suspense>
     </BrowserRouter>
  );
}

export default App;
